body {
    font-family: "Montserrat";
    font-weight: 400;
    /* min-height: 450px; */
    height: 100vh;
    margin: 0;
    /* background: radial-gradient(circle, #006698, #005985, #004C72, #00405F, #00405F); */
    background: radial-gradient(circle, rgba(0, 76, 114, 1) 18%, rgba(0, 60, 91, 1) 59%, rgba(0, 45, 68, 1) 85%);

    background-repeat:repeat;
    background-attachment:fixed;
    overflow:scroll;
    color: #FFF;
}

h1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
}
h2 {
    font-weight: 800;
    font-size: 2.1em;
}
a {
    font-weight: 500;
    text-decoration: none;
    color: #B6C7DB;
}

/* unvisited link */
a:link {
    color: #B6C7DB;
}

/* visited link */
a:visited {
    color: #B6C7DB;
}

/* mouse over link */
a:hover {
    color: #91ABC9;
    text-decoration: underline;
}

/* selected link */
a:active {
    color: #91ABC9;

}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.tag-pro {
    color: #282c34;
    padding: 2px;
    border-radius: 2px;
    background: #FCDB55;
    background: linear-gradient(135deg, #d9b93b, #e1da58);
}

.tag-beta {
    color: #282c34;
    padding: 2px;
    border-radius: 2px;
    background: #FCDB55;
    background: linear-gradient(135deg, #7FBFDF, #4CA6D2);
}

/* Antdesign */
.ant-tag {
    font-weight: 500;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CCCBCC;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #198CC5;
}

input:focus+.slider {
    box-shadow: 0 0 1px #198CC5;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.audit table {
    border-collapse: separate;
    border-spacing: 0;

    border-radius: 10px;
    background: rgba(0, 45, 68, 0.8);
    padding: 4px;

}

.audit td {
    border: 0px solid rgba(229, 242, 248, 0.5);
    border-style: none none solid none;
    padding: 5px;
}

.audit tr:first-child td:first-child {
    border-top-left-radius: 10px;
}

.audit tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

.audit tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.audit tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.audit tr:first-child td {
    border-top-style: solid;
}

.audit td:last-child {
    border-right-style: solid;
}

.audit tr td:first-child {
    border-left-style: solid;
}

/* .audit tr:hover {
    background-color: #00405F;
}

.audit .selected {
    color: #000C13;
    background-color: rgba(229, 242, 248, 0.8);
}

.audit .selected:hover {
    color: #000C13;
    background-color: rgba(229, 242, 248, 0.8);
} */

.audit .tag {
    line-height: 1em;
    font-size: 0.8em;
    border: 1px solid #CCCBCC;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 4px;
    line-height: 4;
    /* padding: 5px; */
    text-transform: uppercase;
    font-weight: 700;
}

.CSScenter {
    position: fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Design IOS */

.container::-webkit-scrollbar, .mainContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container,.mainContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mainContainer{
    z-index: 10;
    /* position: absolute; */
    left: 0px;
    max-width: 1431px;
    width: 100%;
    padding: 10px 5px 5px;
    color: rgb(204, 203, 204);
    font-weight: 300;
    opacity: 1;
    display: flex;
    /* top: 40px; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100svh;
    /* margin-top: 7vh;    */ 
}

.footer{
    background: radial-gradient(circle, rgba(0, 76, 114, 1) 0%, rgba(0, 60, 91, 1) 100%, rgba(0, 45, 68, 1) 85%);
}

.container{
    max-Width: 100%;
    width: 100%;
    /* margin-bottom: 5em; */
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    /* height: 90vh; */
    overflow-y: auto!important;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}

#root > div{
    max-width: 100%!important;
}

/* Form style */

.button--material{
    background-color: #FFFFFF!important;
    color: black!important;
    padding: 6px 16px!important;
}

.text-input--material__label{
    font-family: 'Montserrat'!important;
    font-weight: 300!important;
    font-size: 25px!important;
    left: 20px!important;
    top: 25px!important;
    /* display: none!important; */
}

ons-input .text-input, ons-search-input .search-input{
    font-family: 'Montserrat';
    border: 1px solid #FFF;
    padding: 25px;
    border-radius: 3px;
    margin: 14px 0;    
    color: #FFF;
    font-size: 22px;
    font-weight: 300;
}

.language-container option{
    font-family: 'Montserrat';
    color: #000;
    font-size: 22px;
}

select.select-input.select-input--material{
    font-family: 'Montserrat';
    border: 1px solid #FFF;
    padding: 0px;
    border-radius: 10px;
    margin: 14px 0;
    color: #FFF;
    gap: 10px;
    font-size: 22px;
    font-weight: 300;
    height: 55px;
    padding: 0 20px;
}

.forgot-container{
    text-align: right;
    position: relative;
    right: 0;
    top: -20px;
}

.forgot-container .forgot{
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.form-container{
    /* max-width: 375px; */
    display: flex;
    flex-direction: column;
    /* margin: 30px 0; */
    /* margin: auto; */
}

.form-container p{
    margin: 0;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.center .no-account-btn{
    font-size: 15px;
    cursor: pointer;
    margin: 35px 0;
}

.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 22%;
}

/* #root > div > div#page{
     overflow-y: auto!important;
} */

.toolbar--material__left{
    margin-right:2em;
}

.toolbar--material__left ons-icon{
    margin-right:0.3em;
}

.left.toolbar__left.toolbar--material__left.toolbar--cover-content__left > div{
    color: #FFF;
    margin-right: 2em;
}

.left.toolbar__left.toolbar--material__left.toolbar--cover-content__left > ons-icon{
    top: 0!important;
}

ol.steps{
    margin: 30px 0;
}

ol.steps li {
    font-size: 19px;
    padding: 5px 0;
    line-height: 1.7;
    text-align: left;
}

ol.steps li > strong {
    font-weight: 600;
}

.audit{
    width: 100%;
    margin: 20px 0;
    margin: auto;
    margin-top: 20px;
    max-width: 600px;
}

.policies{
    margin: 20px 0;
    margin: auto;
    margin-top: 20px;
    width: 90%;
    max-width: 600px;
}

.settings{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 90%;
    max-width: 450px;
}

.action-sheet {
    /* max-width: 450px; */
    margin-left: auto;
    margin-right: auto;
}

.audit_table{
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;    
}

.audit_table .domain{
    word-break: break-all;
    white-space: normal;
}

/* Mobile media query */
@media screen and (min-width: 768px) {
  #root > div > div#page div.btn-group {
    margin: 0 !important;
    align-items: center;
    display: flex !important;
    justify-content: space-around;
  }

  #root > div > div#page div.btn-group > div {
    margin: 0 !important;
  }
}

/* Tabs style */
label.ant-segmented-item.ant-segmented-item-selected{
  background-color: #006698; /* Change to your desired background color */
  color: #FFF;
}

label.ant-segmented-item {
    color: #000;
}

/* Card style */
.setting_card{
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 1.7;
    text-align: left;
    border: 1px solid white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.setting_card_title{
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    width: fit-content;
    border-bottom: 1px solid #fff;
}

.setting_card_desc{
    font-size: 12px;
    margin-bottom: 30px;
}

.mini-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}